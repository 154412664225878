import React, { useState, useEffect, useCallback, useRef } from 'react';
import FilterBar from '../FilterBar';
import ProductTable from '../ProductTable';
import RowsPerPage from '../RowsPerPage';

const ProductSection = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filters, setFilters] = useState({
    search: '',
    brand: [],
    diaperType: [],
    size: [],
    weight: ''
  });
  const [sortConfig, setSortConfig] = useState({ key: 'piece_price', direction: 'asc' });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 35; // Changed to 35 rows per page
  const [filterOptions, setFilterOptions] = useState({
    brands: [],
    diaperTypes: [],
    sizes: []
  });

  const productSectionRef = useRef(null);

  const applyFilters = useCallback(() => {
    let result = products;

    if (filters.search) {
      result = result.filter(product => 
        product.product_name.toLowerCase().includes(filters.search.toLowerCase())
      );
    }
    if (filters.brand.length > 0) {
      result = result.filter(product => filters.brand.includes(product.brand));
    }
    if (filters.diaperType.length > 0) {
      result = result.filter(product => filters.diaperType.includes(product.diaper_type));
    }
    if (filters.size.length > 0) {
      result = result.filter(product => filters.size.includes(product.size?.toString()));
    }
    if (filters.weight) {
      const weight = parseFloat(filters.weight);
      result = result.filter(product => weight >= product.weight_min && weight <= product.weight_max);
    }

    setFilteredProducts(sortProducts(result));
    setCurrentPage(1); // Reset to first page when filters change
  }, [filters, products]);

  const handleFilterChange = (name, value) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const toggleFilterOption = (filterName, option) => {
    setFilters(prevFilters => {
      const currentOptions = prevFilters[filterName];
      if (currentOptions.includes(option)) {
        return {
          ...prevFilters,
          [filterName]: currentOptions.filter(item => item !== option)
        };
      } else {
        return {
          ...prevFilters,
          [filterName]: [...currentOptions, option]
        };
      }
    });
  };

  const clearFilters = () => {
    setFilters({
      search: '',
      brand: [],
      diaperType: [],
      size: [],
      weight: ''
    });
  };

  const sortProducts = useCallback((productsToSort) => {
    return [...productsToSort].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];

      // Push null values to the bottom regardless of sort direction
      if (aValue === null && bValue === null) return 0;
      if (aValue === null) return 1;
      if (bValue === null) return -1;

      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }, [sortConfig]);

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    setCurrentPage(1); // Reset to first page when sorting changes
  };

  useEffect(() => {
    fetch('https://beebiheaks.ee/api/products')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        if (data && Array.isArray(data.data)) {
          setProducts(data.data);
          setFilteredProducts(data.data);
          
          // Extract distinct values for filter options
          const brands = [...new Set(data.data.map(product => product.brand).filter(Boolean))].sort();
          const diaperTypes = [...new Set(data.data.map(product => product.diaper_type).filter(Boolean))].sort();
          const sizes = [...new Set(data.data.map(product => product.size).filter(size => size !== null && size !== undefined))]
            .sort((a, b) => a - b)
            .map(size => size.toString());

          setFilterOptions({ brands, diaperTypes, sizes });
        } else {
          throw new Error('Invalid data format received from server');
        }
      })
      .catch(error => {
        console.error('Error fetching products:', error);
        console.error('Error details:', error.message);
      });
  }, []);

  useEffect(() => {
    applyFilters();
  }, [applyFilters, filters]);

  useEffect(() => {
    setFilteredProducts(prevFilteredProducts => sortProducts(prevFilteredProducts));
  }, [sortConfig, sortProducts]);

  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  const paginatedProducts = filteredProducts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const scrollToTop = () => {
    productSectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="my-8" ref={productSectionRef}>
      <FilterBar
        filters={filters}
        filterOptions={filterOptions}
        handleFilterChange={handleFilterChange}
        toggleFilterOption={toggleFilterOption}
        clearFilters={clearFilters}
      />
      <ProductTable
        filteredProducts={paginatedProducts}
        sortConfig={sortConfig}
        requestSort={requestSort}
      />
      <RowsPerPage
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        filteredProducts={filteredProducts}
        scrollToTop={scrollToTop}
      />
    </div>
  );
};

export default ProductSection;