import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/logo.svg'; // Make sure this path is correct
import SparklesText from '../SparklesText'; // Adjust the path as necessary

const Header = () => {
  const [theme, setTheme] = useState('cupcake');
  const [emailType, setEmailType] = useState('');

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'cupcake' ? 'dim' : 'cupcake');
  };

  const handleEmailClick = (type) => {
    setEmailType(type);
    setTimeout(() => {
      window.location.href = `mailto:beebiheaks@gmail.com?subject=${encodeURIComponent(type)}`;
    }, 100); // Slight delay to allow dropdown to close
  };

  return (
    <header className="py-4">
      <div className="container mx-auto px-4 flex items-center justify-between">
        <div className="flex items-center">
          <img src={logo} alt="BeebiHeaks Logo" className="w-14 h-14 mr-2 fill-current" />
          <SparklesText text="beebi heaks" className="text-4xl font-bold font-playwrite1" />
        </div>
        <div className="flex items-center gap-4">
          <label className="flex cursor-pointer gap-2 items-center">
            <FontAwesomeIcon icon={faSun} className="text-xl" />
            <input
              type="checkbox"
              className="toggle theme-controller"
              checked={theme === 'dim'}
              onChange={toggleTheme}
            />
            <FontAwesomeIcon icon={faMoon} className="text-xl" />
          </label>
          <div className="dropdown dropdown-end">
            <div tabIndex={0} className="btn btn-outline btn-primary">
              <FontAwesomeIcon icon={faEnvelope} className="text-xl" />
            </div>
            <ul tabIndex={0} className="dropdown-content menu bg-base-100 rounded-box z-[1] w-52 p-2 shadow">
              <li><a onClick={() => handleEmailClick('Feature Request')}>Feature Request</a></li>
              <li><a onClick={() => handleEmailClick('Bug Report')}>Bug Report</a></li>
              <li><a onClick={() => handleEmailClick('Info Request')}>Info Request</a></li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
