import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxesStacked, faTag, faIdCardClip, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import BoxReveal from '../BoxReveal'; // Adjust the import path as necessary

const InfoSection = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 text-base-content">
      <section>
        <BoxReveal duration={0.5}>
          <h2 className="text-2xl font-bold mb-4 font-playwrite1 font-playwrite1-400">Meist</h2>
        </BoxReveal>
        <BoxReveal duration={0.5}>
          <p className="mb-4">
            Lõime koha, kus kõik vanemad saavad hõlpsasti leida parimaid pakkumisi
            mähkmetele. Meie eesmärk on aidata teil leida soodsaimad mähkmed, et
            teil jääks rohkem aega ja raha kõige tähtsamale -- oma armsa beebi
            rõõmustamiseks!
          </p>
        </BoxReveal>
        <BoxReveal duration={0.5}>
          <p className="mb-4">
            Meie andmebaasis on üle 1000 rea ning tegeleme andmete korrastamisega
            jooksvalt. Palume seda silmas pidada filtreerimisel. Kõige kindlam on
            otsida vaba tekstiga.
          </p>
        </BoxReveal>
        <BoxReveal duration={0.5}>
          <p>
            Kontrollige kindlasti allolevaid linke, et saada kinnitust
            soodustustele.
          </p>
        </BoxReveal>
      </section>
      <section>
        <BoxReveal duration={0.5}>
          <h2 className="text-2xl font-bold mb-4 font-playwrite1 font-playwrite1-400">Legend</h2>
        </BoxReveal>
        <ul className="space-y-2">
          <BoxReveal duration={0.5}>
            <li className="flex items-center">
              <div className="w-10 flex justify-center">
                <FontAwesomeIcon icon={faBoxesStacked} className="fa-xl" />
              </div>
              <span>Hulgipakkumine</span>
            </li>
          </BoxReveal>
          <BoxReveal duration={0.5}>
            <li className="flex items-center">
              <div className="w-10 flex justify-center">
                <FontAwesomeIcon icon={faTag} className="fa-xl" />
              </div>
              <span>Sooduspakkumine</span>
            </li>
          </BoxReveal>
          <BoxReveal duration={0.5}>
            <li className="flex items-center">
              <div className="w-10 flex justify-center">
                <FontAwesomeIcon icon={faIdCardClip} className="fa-xl" />
              </div>
              <span>Kliendikaardi pakkumine</span>
            </li>
          </BoxReveal>
          <BoxReveal duration={0.5}>
            <li className="flex items-center">
              <div className="w-10 flex justify-center">
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="fa-xl" />
              </div>
              <span>Viide</span>
            </li>
          </BoxReveal>
        </ul>
      </section>
    </div>
  );
};

export default InfoSection;
