import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxesStacked, faTag, faIdCardClip, faArrowUpRightFromSquare, faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

const ProductTable = ({ filteredProducts, rowsToShow, sortConfig, requestSort }) => {
  const sortedProducts = useMemo(() => {
    const getComparableValue = (product, key) => {
      if (key === 'piece_price') {
        return product.discount_piece_price ?? product.piece_price;
      }
      if (key === 'price') {
        return product.discount_price ?? product.price;
      }
      return product[key];
    };

    const sortableProducts = [...filteredProducts];
    sortableProducts.sort((a, b) => {
      const aValue = getComparableValue(a, sortConfig.key);
      const bValue = getComparableValue(b, sortConfig.key);

      if (aValue === null || aValue === undefined) return 1;
      if (bValue === null || bValue === undefined) return -1;

      if (sortConfig.key === 'price' || sortConfig.key === 'piece_price') {
        return sortConfig.direction === 'asc'
          ? parseFloat(aValue) - parseFloat(bValue)
          : parseFloat(bValue) - parseFloat(aValue);
      } else {
        return sortConfig.direction === 'asc'
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
    });
    return sortableProducts.slice(0, rowsToShow);
  }, [filteredProducts, sortConfig, rowsToShow]);

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? faSortUp : faSortDown;
    }
    return faSort;
  };

  return (
    <div className="overflow-x-auto">
      <table className="table table-zebra w-full">
        <thead>
          <tr className="text-base font-bold">
            <th onClick={() => requestSort('product_name')} className="cursor-pointer">
              Toote nimi <FontAwesomeIcon icon={getSortIcon('product_name')} />
            </th>
            <th onClick={() => requestSort('price')} className="cursor-pointer">
              Hind <FontAwesomeIcon icon={getSortIcon('price')} />
            </th>
            <th onClick={() => requestSort('piece_price')} className="cursor-pointer">
              TK hind <FontAwesomeIcon icon={getSortIcon('piece_price')} />
            </th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          {sortedProducts.map(product => (
            <tr key={product.id}>
              <td>
                {product.product_name}
                {product.bulk === 1 && <FontAwesomeIcon icon={faBoxesStacked} className="ml-2 text-base" />}
                {product.discount_price && <FontAwesomeIcon icon={faTag} className="ml-2 text-base" />}
                {product.loyalty === 1 && <FontAwesomeIcon icon={faIdCardClip} className="ml-2 text-base" />}
              </td>
              <td>{product.discount_price || product.price} €</td>
              <td>{product.discount_piece_price || product.piece_price} €/tk</td>
              <td>
                <a href={product.product_link} target="_blank" rel="noopener noreferrer" className="btn btn-sm">
                  {product.shop_name} <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ml-1 text-base" />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProductTable;
