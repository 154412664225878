import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faChevronDown, faSearch, faFilter } from '@fortawesome/free-solid-svg-icons';

const FilterBar = ({ filters, filterOptions, handleFilterChange, toggleFilterOption, clearFilters }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFilters = () => setIsOpen(!isOpen);

  const renderFilterDropdown = (name, options, filterKey) => (
    <div className="dropdown dropdown-hover w-full h-full">
      <div tabIndex={0} role="button" className="btn btn-outline w-full h-full justify-between">
        {filters[filterKey].length > 0 ? `${filters[filterKey].length} selected` : name}
        <FontAwesomeIcon icon={faChevronDown} className="ml-2" />
      </div>
      <ul tabIndex={0} className="dropdown-content menu bg-base-100 rounded-box z-[1] w-full p-2 shadow max-h-[60vh] overflow-y-auto">
        {options.map((option, index) => (
          <li key={index}>
            <label className="label cursor-pointer justify-start">
              <input 
                type="checkbox" 
                className="checkbox checkbox-sm mr-2"
                checked={filters[filterKey].includes(option)}
                onChange={() => toggleFilterOption(filterKey, option)}
              />
              <span className="label-text">{option}</span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <div className="sticky top-0 z-10 bg-base-200 shadow-md">
      <div className="lg:hidden">
        <div className="flex items-center justify-between p-2">
          <button className="btn btn-sm btn-outline" onClick={toggleFilters}>
            <FontAwesomeIcon icon={faFilter} className="mr-2" />
            Filters
          </button>
          <div className="relative flex-grow mx-2">
            <input
              type="text"
              placeholder="Otsi toodet"
              className="input input-bordered input-sm w-full"
              name="search"
              value={filters.search}
              onChange={(e) => handleFilterChange('search', e.target.value)}
            />
            <FontAwesomeIcon icon={faSearch} className="absolute right-3 top-2 text-gray-500" />
          </div>
          <button className="btn btn-sm btn-ghost" onClick={clearFilters}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        {isOpen && (
          <div className="p-2 space-y-2">
            {renderFilterDropdown('Bränd', filterOptions.brands, 'brand')}
            {renderFilterDropdown('Tüüp', filterOptions.diaperTypes, 'diaperType')}
            {renderFilterDropdown('Suurus', filterOptions.sizes, 'size')}
            <input
              type="number"
              placeholder="Kaal (kg)"
              className="input input-bordered input-sm w-full"
              name="weight"
              value={filters.weight}
              onChange={(e) => handleFilterChange('weight', e.target.value)}
            />
          </div>
        )}
      </div>
      <div className="hidden lg:block p-4">
        <div className="grid grid-cols-5 gap-2">
          <div className="relative">
            <input
              type="text"
              placeholder="Otsi toodet"
              className="input input-bordered w-full h-full"
              name="search"
              value={filters.search}
              onChange={(e) => handleFilterChange('search', e.target.value)}
            />
            <FontAwesomeIcon icon={faSearch} className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500" />
          </div>
          {renderFilterDropdown('Bränd', filterOptions.brands, 'brand')}
          {renderFilterDropdown('Tüüp', filterOptions.diaperTypes, 'diaperType')}
          {renderFilterDropdown('Suurus', filterOptions.sizes, 'size')}
          <div className="flex h-full">
            <input
              type="number"
              placeholder="Kaal (kg)"
              className="input input-bordered w-full h-full"
              name="weight"
              value={filters.weight}
              onChange={(e) => handleFilterChange('weight', e.target.value)}
            />
            <button className="btn btn-ghost ml-2 h-full" onClick={clearFilters}>
              <FontAwesomeIcon icon={faTimes} size="lg" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterBar;