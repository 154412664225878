import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import InfoSection from './components/InfoSection';
import ProductSection from './components/ProductSection';
import RowsPerPage from './components/RowsPerPage';

function App() {
  return (
    <div data-theme="retro" className="min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow bg-base-300 text-base-content">
        <div className="container mx-auto px-4 py-8 text-justify">
          <InfoSection />
          <ProductSection />
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default App;